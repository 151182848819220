<template>
  <div>
    <div class="mobile placeholder-header"></div>
    <div class="head">
      <div @click="store1.isConsultingSidebar = true" class="mobile">
        <img
          style="width: 20px; height: 20px; margin-top: 12px"
          src="@/assets/navImg.png"
          alt=""
        />
      </div>
      <div class="head-left" @click="goOnlineExpert">
        <img src="../../assets/mainLogo.png" alt="" />
      </div>
      <p class="head-right" @click="conLogOut">
        <svg-icon icon="tuichu"></svg-icon>

        <span style="margin-left: 10px; color: #000000">Sign Out</span>
      </p>
    </div>
    <div class="middle pc">
      <div class="content">
        <el-container>
          <el-aside width="260px">
            <div class="content-left">
              <div class="left-avatar">
                <ImageUpload
                  v-model:imgUrl="state.avatarImg"
                  mode="avatar"
                  :limit="1"
                  @update:imgUrl="updateimgUrl"
                >
                </ImageUpload>
                <h4>{{ state.ConInformation.nickname }}</h4>
                <!-- <p>详情2</p> -->
              </div>
              <el-menu
                :default-active="'1'"
                class="el-menu-vertical-demo"
                background-color="#02758a"
                router
                text-color="#fff"
                active-text-color="#FFE400"
              >
                <template v-for="item in elMentList" :key="item.id">
                  <el-sub-menu class="menu" v-if="item.children" :index="item.id">
                    <template #title v-if="item.children">
                      <span>{{ item.name }}</span>

                      <el-badge
                        v-if="item.name == 'Messenger'"
                        :value="
                          store1.gwMsgArr.sumUnread ? store1.gwMsgArr.sumUnread : ''
                        "
                        :max="99"
                        class="msgItem"
                      ></el-badge>
                    </template>
                    <el-menu-item
                      :style="{
                        height:
                          i.name == 'Dashboard' || i.name == 'Messenger' ? '0px' : '50px',
                      }"
                      class="menu"
                      v-for="i in item.children"
                      :key="i.id"
                      :index="i.id"
                    >
                      <div v-if="i.name !== 'Dashboard' && i.name !== 'Messenger'">
                        {{ i.name }}
                      </div>
                      <el-badge
                        v-if="i.name == 'Messenger '"
                        :value="
                          store1.gwMsgArr.sumUnread ? store1.gwMsgArr.sumUnread : ''
                        "
                        :max="99"
                        class="msgItem"
                      ></el-badge>
                    </el-menu-item>
                  </el-sub-menu>
                  <el-menu-item class="menu-out" v-else :index="item.id">{{
                    item.name
                  }}</el-menu-item>
                </template>
              </el-menu>
            </div>
          </el-aside>
          <el-container>
            <el-main>
              <router-view
                :consultantId="state.ConInformation.id"
                @getInformation="getInformation"
              />
            </el-main>
          </el-container>
        </el-container>
      </div>
    </div>

    <!-- 移动端样式 -->

    <!-- 侧边栏 -->
    <div class="content mobile">
      <el-container>
        <el-aside :width="store1.isConsultingSidebar ? '230px' : '0px'">
          <div class="content-left">
            <div class="left-avatar">
              <div style="margin: 10px 30px 0px 67px">
                <ImageUpload
                  v-model:imgUrl="state.avatarImg"
                  mode="avatar"
                  :limit="1"
                  @update:imgUrl="updateimgUrl"
                >
                </ImageUpload>
              </div>
              <h4 style="text-align: center">{{ state.ConInformation.nickname }}</h4>
              <div class="chahao">
                <img
                  @click="store1.isConsultingSidebar = false"
                  style="widht: 20px; height: 20px"
                  src="@/assets/chaHao.png"
                  alt=""
                />
              </div>
              <!-- <p>详情2</p> -->
            </div>
            <el-menu
              :default-active="'1'"
              class="el-menu-vertical-demo"
              background-color="#02758a"
              router
              text-color="#fff"
              active-text-color="#FFE400"
            >
              <template v-for="item in elMentList" :key="item.id">
                <el-sub-menu class="menu" v-if="item.children" :index="item.id">
                  <template #title v-if="item.children">
                    <span>{{ item.name }}</span>
                    <el-badge
                      v-if="item.name == 'Messenger'"
                      :value="store1.gwMsgArr.sumUnread ? store1.gwMsgArr.sumUnread : ''"
                      :max="99"
                      style="margin-bottom: 45px; margin-left: 10px"
                    ></el-badge>
                  </template>
                  <el-menu-item
                    class="menu"
                    :style="{
                      height:
                        i.name == 'Boardcast' || i.name == 'Messenger ' ? '0px' : '50px',
                    }"
                    v-for="i in item.children"
                    :key="i.id"
                    :index="i.id"
                  >
                    <div v-if="i.name !== 'Boardcast' && i.name !== 'Messenger '">
                      {{ i.name }}
                    </div>

                    <el-badge
                      v-if="i.name == 'Messenger'"
                      :value="store1.gwMsgArr.sumUnread ? store1.gwMsgArr.sumUnread : ''"
                      :max="99"
                      style="margin-bottom: 45px; margin-left: 10px"
                    ></el-badge>
                  </el-menu-item>
                </el-sub-menu>
                <el-menu-item class="menu-out" v-else :index="item.id">{{
                  item.name
                }}</el-menu-item>
              </template>
            </el-menu>
          </div>
        </el-aside>
        <el-container>
          <el-main>
            <router-view
              :consultantId="state.ConInformation.id"
              @getInformation="getInformation"
            />
          </el-main>
        </el-container>
      </el-container>
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script setup>
// import router from "../../router";
import { useRouter, useRoute } from "vue-router";
import { phoneStore } from "../../store/phone1";
import Footer from "../.././layout/components/Footer.vue";
import { reactive, ref, onMounted, watch } from "vue";
import {
  getConmation,
  reviseConsulantInformation,
  updateConPass,
  logOut,
} from "@/api/center.js";
import ImageUpload from "@/components/ImageUpload";
import { removeToken, removeType, removeConlant } from "@/utils/auth";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
// import  { UploadProps, UploadUserFile } from 'element-plus'
const router = useRouter();
const route = useRoute();
const store1 = phoneStore();
const state = reactive({
  circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
  isShow: "",
  activeName: "frist",
  disabled: true,
  checkList: [],
  incomeType: "",
  ConInformation: {}, //刚进页面获取的信息
  tableData: [], ///直播收益列表
  chatData: [], //聊天收益
  withData: [], //提现收益
  avatarImg: "",
  ImageUrl: process.env.VUE_APP_BASE_ImageUrl,
});
const isShowMsg = ref(true);
const store = useStore();
const elMentList = reactive([
  {
    id: "/profileInfo/home",
    name: "Home",
  },
  {
    id: "2",
    name: "Statistics",
    children: [
      {
        id: "/profileInfo/liveIncome",
        name: "Live Income (ComingSoon)",
      },
      {
        id: "/profileInfo/PrivateChatIncome",
        name: "Private Chat Income",
      },
      {
        id: "/profileInfo/Boardcast",
        name: "Broadcast (Coming Soon)",
      },
      {
        id: "/mobileEcharts",
        name: "Dashboard",
      },

      {
        id: "/profileInfo/WithdrawalList",
        name: "Withdrawal List",
      },
    ],
  },
  {
    id: "3",
    name: "Profile",
    children: [
      {
        id: "/profileInfo/ProfilePhoto",
        name: "Profile Photo",
      },
      {
        id: "/profileInfo/PersonalData",
        name: "Personal Data",
      },
      {
        id: "/profileInfo/Price",
        name: "Price",
      },
    ],
  },
  {
    id: "4",
    name: "Messenger",
    children: [
      // {
      //   id: '/profileInfo/MailboxList',
      //   name: 'Mailbox List'
      // },
      // {
      //   id: "/profileInfo/CommentManagementList",
      //   name: "Comment Management List",
      // },
      {
        id: "/profileInfo/message",
        name: "Messenger ",
      },
      {
        id: "/mobleMsg?type=1",
        name: "Messenger",
      },
    ],
  },
  {
    id: "/profileInfo/reservation",
    name: "Scheduler",
  },
]);

watch(
  () => store1.gwMsgArr.sumUnread,
  (n) => {
    if (n > 0) {
      console.log(n, "store1.gwMsgArr.sumUnread");
    }
  }
);

watch(
  () => route.path,
  (n) => {
    // console.log(n, "n");
    // if (n === "/profileInfo/message" && window.innerWidth < 769) {
    //   router.push({
    //     path: "/mobleMsg",
    //     query: {
    //       type: "1",
    //     },
    //   });
    //   return;
    // }
    // if (n === "/profileInfo/Dashboard" && window.innerWidth < 769) {
    //   router.push({
    //     path: "/mobileEcharts",
    //   });
    //   store1.isConsultingSidebar = false;
    //   return;
    // }
    store1.isConsultingSidebar = false;
  }
);

onMounted(() => {
  console.log(elMentList, "elMentList-----------------------");

  getInformation();
});

const getInformation = (obj) => {
  console.log(obj, "objkk");
  // console.log(obj) {a:1,b:2}
  getConmation().then((res) => {
    if (res.code == 200) {
      state.ConInformation = res.data;
      state.avatarImg = res.data.avatarImage;
    }
  });
};
//修改头像
const updateimgUrl = (e) => {
  console.log(e, "eee");
  console.log("-----------------------------------------");
  const param = { avatarImage: e, id: state.ConInformation.id };
  reviseConsulantInformation(param).then((res) => {
    if (res.code == 200) {
      getInformation();
    } else {
      console.log("errerrerr");
    }
  });
};
const conLogOut = () => {
  ElMessageBox.confirm("Are you sure you want out?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      ElMessage({
        type: "success",
        message: "Exit successfully",
      });
      logOut().then(() => {
        removeToken();
        removeType();
        removeConlant();
        localStorage.clear();
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        store.state.userInfo = null;
        router.push("/onlineExpert");
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "unexit",
      });
    });

  return;
};
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
</script>

<style lang="scss" scoped>
/* PC端样式 */
@media screen and (min-width: 769px) {
  .msgItem {
    margin-bottom: 55px;
    margin-left: 10px;
  }
  .mobile {
    display: none;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    line-height: 45px;
    // background: #02758a;
    color: blue;

    .head-left {
      cursor: pointer;
      margin-top: 6px;

      img {
        width: 246.3px;
        height: 70px;
      }
    }

    .head-right {
      cursor: pointer;
      font-size: 18px;
    }
  }

  .middle {
    width: 100%;
    background: #ede6f4;
    height: 100%;
    min-height: 1100px;
    overflow: hidden;

    .content {
      width: 1300px;
      // background-color: #02758a;
      margin: auto;
      margin-top: 30px;

      .left-avatar {
        width: 100%;
        height: 283px;
        margin: auto;
        padding: 20px;
        background-color: #02758a;
        text-align: center;

        h4 {
          margin-top: 15px;
          color: #ffff;
        }

        p {
          color: rgb(211, 194, 229);
          margin-top: 5px;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .el-menu {
        border: none;
        height: 740px;
      }

      .el-main {
        padding: 0;
        background-color: #fff;
      }

      .el-sub-menu {
        border-bottom: 1px solid #10525e;
      }

      .menu-out {
        border-bottom: 1px solid #10525e;
      }
    }
  }

  .el-input__wrapper {
    width: 100px;
  }

  .el-form-item {
    width: 450px;
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  :deep(.el-main) {
    height:90vh;
    // padding: 1px !important;
    
    // box-sizing: border-box !important;
  }
  :deep(.el-upload) {
    --el-upload-picture-card-size: 100px;
  }
  :deep(.el-upload-list) {
    --el-upload-list-picture-card-size: 100px;
  }

  .placeholder-header {
    height: 45px; /* 占位头部的高度 */
  }
  :deep(.el-aside) {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 100;
    background: #eee;
    height: 100%;
  }

  .chahao {
    // display:block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .middle {
    .left-avatar {
      position: relative;
      padding-left: 30px;
    }
  }
  .pc {
    display: none;
  }
  .head {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed; /* 固定在页面顶部 */
    top: 0; /* 顶部对齐 */
    left: 0; /* 左边对齐 */
    right: 0; /* 右边对齐 */
    z-index: 9;
    background: #eee;

    .head-right {
      background-color: #bddbe4;
      padding: 0 5px;
      box-shadow: inset 0 -2px 0 grey;
    }

    .head-left {
      width: 140.75px;
      height: 40px;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .head-right {
      line-height: 45px;
    }
  }
}
</style>
